function openServices() {
    let left = window.innerWidth / 2 - 800 / 2,
      top = window.innerHeight / 2 - 230,
      features = "left=$left,top=$top,width=800,height=618"
        .replace("$top", top)
        .replace("$left", left);
        
    window.open('https://tb.53kf.com/code/client/10194018/2', "openQQService",features)
  }
  
  
  Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  }
  
  function localSave(key, value) {
    if (arguments.length == 1) {
      let item = sessionStorage.getItem(key)
      if(item) {
        return JSON.parse(sessionStorage.getItem(key));
      } 
      return ''
    } else if (arguments.length == 2) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  // 中国标准时间转化成时间戳
function dateToMs(date) {
  let result = new Date(date).getTime();
  return result;
}
  
  //时间戳转 201803032305 格式 或自定义年月的分隔符
  function transCommDate(ts, s) {
    var D, y, M, d, H, m, time;
  
    function format(n) {
      if (n < 10) {
        return "0" + n;
      }
      return n;
    }
  
    s = s || "";
    D = new Date(ts);
    y = D.getFullYear();
    M = format(D.getMonth() + 1);
    d = format(D.getDate());
    H = format(D.getHours());
    m = format(D.getMinutes());
    time = y + s + M + s + d + s + H + s + m;
    return time;
  }
  
  //时间戳转 201803032305 格式 或自定义年月的分隔符
  function transCommDates(ts, s) {
    var D, y, M, d, H, m, time;
  
    function format(n) {
      if (n < 10) {
        return "0" + n;
      }
      return n;
    }
  
    s = s || "";
    D = new Date(ts);
    y = D.getFullYear();
    M = format(D.getMonth() + 1);
    d = format(D.getDate());
    H = format(D.getHours());
    m = format(D.getMinutes());
    time = y + s + M + s + d;
    return time;
  }
  //时间戳转中文时间
  function transChineseDate(ts) {
    var D, y, M, d, h, m, s, time;
  
    function format(n) {
      if (n < 10) {
        return "0" + n;
      }
      return n;
    }
  
    D = new Date(ts);
    y = D.getFullYear() + "年";
    M = D.getMonth() + 1 + "月";
    d = D.getDate() + "日 ";
    h = format(D.getHours()) + ":";
    m = format(D.getMinutes()) + ":";
    s = format(D.getSeconds());
    time = y + M + d + h + m + s;
    return time;
  }
  
  //格式化时间戳
  function formatterDate(ts, sp){
    if(!sp){
      sp = '-'
    }
    const t = new Date(ts)
    const y = t.getFullYear().toString();
    const M = (t.getMonth() + 1).toString();
    const d = t.getDate().toString();
    const h = t.getHours().toString();
    const m = t.getMinutes().toString();
    const s = t.getSeconds().toString();
    const date = `${y}${sp}${M.padStart(2, 0)}${sp}${d.padStart(2, 0)} ${h.padStart(2, 0)}:${m.padStart(2, 0)}:${s.padStart(2, 0)}`
    return date;
  }
  
  //获取当前年月日
  function getDateTime() {
    let d = new Date();
  
    function format(n) {
      if (n < 10) {
        return "0" + n;
      }
      return n;
    }
  
    return d.getFullYear() + '-' + format(d.getMonth() + 1) + '-' + format(d.getDate());
  }
  
  //获取前7天
  function getDateTimeSeven() {
    let d = new Date();
    d.setDate(d.getDate() - 7);
    let s1 = d.format("yyyy-MM-dd");
    return s1;
  }
  
  function getDateTimeFifteen() {
    let d = new Date();
    d.setDate(d.getDate() - 15);
    let s1 = d.format("yyyy-MM-dd");
    return s1;
  }
  
  //获取近一个月
  function getDateTimeMonth() {
    let d = new Date();
    d.setDate(d.getDate() - 30);
    let s1 = d.format("yyyy-MM-dd");
    return s1;
  }
  
  function getDateTimePass(pass) {
    let d = new Date();
    d.setDate(d.getDate() - pass);
    let s1 = d.format("yyyy-MM-dd");
    return s1;
  }
  
  
  //获取昨天
  function getDateTimeYesterday(symbol = '-') {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    let s1 = d.format(`yyyy${symbol}MM${symbol}dd`);
    return s1;
  }
  
  //获取明天
  function getDateTimeTomorrow() {
    let d = new Date();
    d.setDate(d.getDate() + 1);
    let s1 = d.format("yyyy-MM-dd");
    return s1;
  }
  
  //获取今天
  function getDateTimeToday(symbol = '-') {
    let d = new Date();
    d.setDate(d.getDate());
    let s1 = d.format(`yyyy${symbol}MM${symbol}dd`);
    return s1;
  }
  
  //有效期format
  function wxValidityDate (_info, type=1) {
    if(!_info) {
      return
    }
    let indate_fixed = [],indate_after = 0,indate_validateDay = 0
    let info = _info.date_info ? _info.date_info : _info
    if(Object.keys(info).length > 0) {
      type = info.type ? info.type : 1
      //固定日期
      if(type === 1 || type === 'DATE_TYPE_FIX_TIME_RANGE') {
        indate_fixed = [info.begin_timestamp, info.end_timestamp]
      }
      //领取后
      if(type === 2 || type === 'DATE_TYPE_FIX_TERM') {
        indate_after = info.fixed_begin_term
        indate_validateDay = info.fixed_term
      }
      if((type === 1 || type === 'DATE_TYPE_FIX_TIME_RANGE') && indate_fixed) {
        let arr = []
        if(/^[0-9]+$/.test(indate_fixed[0])) {
          let time = indate_fixed[0].toString().length > 10 ? indate_fixed[0] : indate_fixed[0]*1000
          arr.push(transCommDates(time, '-'))
        } else {
          arr.push(indate_fixed[0])
        }
        if(/^[0-9]+$/.test(indate_fixed[1])) {
          let time = indate_fixed[1].toString().length > 10 ? indate_fixed[1] : indate_fixed[1]*1000
          arr.push(transCommDates(time, '-'))
        } else {
          arr.push(indate_fixed[1])
        }
        if(arr.length) {
          return `${arr[0]} 至 ${arr[1]}`
        }
        return `${indate_fixed[0]} 至 ${indate_fixed[1]}`
  
      } else if(type === 2 || type === 'DATE_TYPE_FIX_TERM'){
        if(type === 1) {
          let d = new Date()
          d.setDate(d.getDate() + indate_after);
          let start = d.format("yyyy-MM-dd");
          d.setDate(d.getDate() + indate_validateDay);
          let end = d.format("yyyy-MM-dd");
          return `${start} 至 ${end}`
        } else {
          return `领取后${indate_after===0?'当':indate_after}天生效，${indate_validateDay}天内有效`
        }
      }
    }
    return ''
  }
  
  //卡券可用时段format
  function intimeDayChinese (_info) {
    if(!_info) {
      return
    }
    let result = []
    let item = []
    let prev = 0
    //可用时段
    let info = _info.week ? JSON.parse(JSON.stringify(_info.week)) : JSON.parse(JSON.stringify(_info))
    let chinese = ['周一','周二','周三','周四','周五','周六','周日','','节假日']
    let weekEnglish = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY', '', 'HOLIDAY']
    // 把英文转为数字
    let intime_day = Array.from(info, x => {
      return typeof x !== 'number' ? (weekEnglish.indexOf(x.type) + 1) : x
    })
  
    if(!intime_day || !intime_day.length || intime_day[0] === 0) { //intime_day[0] === 0是只有hour没有week
      return ''
    }
    // 查找连续的日期放到同一个数组中
    intime_day.sort().forEach(v => {
      if(item.length === 0) {
        item.push(v)
      } else {
        if((prev + 1) !== v) {
          result.push(item.splice(0, item.length)) //起始位置，剪切个数，不可省略
        }
        item.push(v)
      }
      prev = v
    })
    result.push(item.splice(0, item.length))
    return Array.from(result, v => chinese[v[0]-1] + (v.length > 1 ? `至${chinese[v[v.length-1]-1]}` : '')).join('、')
  }
  
  //卡券可用时段分秒format
  function intimeMinChinese (_info) {
    if(!_info) {
      return
    }
    let time = []
    let info = _info.hour ? JSON.parse(JSON.stringify(_info.hour)) : JSON.parse(JSON.stringify(_info))
  
    if(info.length > 0) {
      info.forEach((v,i) => {
        if(v.beginHour !== undefined && v.beginHour !== '') {
          if(i > 0) {
            time.push('&nbsp;&nbsp;')
          }
          if(v) {
            let str = v.beginHour.toString().padStart(2,0) + ':' + v.beginMinute.toString().padStart(2,0)
            if(v.endHour) {
              str += '-' + v.endHour.toString().padStart(2,0) + ':' + v.endMinute.toString().padStart(2,0)
            }
            time.push(str)
          }
        }
      })
    }
    if(time.length > 0) {
      return time.join('')
    }
    return '全天通用'
  }
  
  /** 获取时间跨度
   * range Number 时间跨度参数
   * range === 0   昨天
   * range === 1   今天
   * range === 7   7天
   * range === 30  30天
   */
  function getDateRange(range) {
    let arr = [];
    if(range === 1 || range === 0) {
      for(let i=0; i< 24; i++) {
        arr.push(`${i.toString().padStart(2,0)}:00`)
      }
      if(range === 1) {
        arr.unshift(getDateTimeToday()) // 今天
      } else {
        arr.unshift(getDateTimeYesterday()) // 昨天
      }
    } else {
       for(let i=0; i<range; i++) {
        let d = new Date()
        d.setDate(d.getDate() - i)
        arr.push(d.format('yyyy-MM-dd'))
      }
      arr.reverse()
    }
    return arr
  }
  
  // 判断卡券是否过期
  function judgeCouponExpire(date) {
    let status = false
    // 固定日期
    if(date.type === 1 || date.type === 'DATE_TYPE_FIX_TIME_RANGE') {
      let d = new Date(date.end_timestamp)
      let end = new Date(d.format('yyyy-MM-dd') + ' 23:59:59')
      if(Date.now() > end.getTime()) {
        // 已过期
        status = true
      }
    }
    return status
  }
  
  //判断图片尺寸
  function judgeImageSize(width, height, file) {
    const fr = new FileReader();
    fr.readAsDataURL(file.raw);
    return new Promise(function (resolve, reject) {
      fr.addEventListener("load", function (e) {
        let url = e.target.result;
        let img = new Image();
        img.src = url;
        img.addEventListener("load", function (e) {
          if (this.width === width && this.height === height) {
            resolve(true)
          } else {
            resolve(false)
          }
        });
      })
    });
  }
  
  //剔除空值
  function objFilter(obj) {
    let newObj = {};
    for (let k in obj) {
      let v = obj[k];
      if ((typeof v === 'string' && v.trim() !== '') || (typeof v === 'number' && v >= 0) || (v instanceof Array && v.length > 0)) {
        newObj[k] = v;
      }
    }
    return newObj;
  }
  
  //随机颜色
  function randomColor() {
    var colorStr = "";
    //字符串的每一字符的范围
    var randomArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
    //产生一个六位的字符串
    for (var i = 0; i < 6; i++) {
      //15是范围上限，0是范围下限，两个函数保证产生出来的随机数是整数
      colorStr += randomArr[Math.ceil(Math.random() * (15 - 0) + 0)];
    }
    return "#" + colorStr;
  }
  
  //转换css key
  function transCssKey(key) {
    return key.replace(/_/g, "-")
  }
  
  //转换css value
  function tranCssValue(v) {
    if (typeof v == "string") {
      v = parseFloat(v.replace("/\%|px|em/g", ""))
    }
    return v
  }
  
  //判断数组对象[{}] 的key 值是否存在
  function checkObjValue(list, key, value) {
    for (var i = 0; i < list.length; i++) {
      if (list[i][key] == value) return true;
    }
    return false;
  }
  
  //判断mac 是否合法
  function checkMac(text) {
    var pattern = /[0-9a-fA-F]{2}\:[0-9a-fA-F]{2}\:[0-9a-fA-F]{2}\:[0-9a-fA-F]{2}\:[0-9a-fA-F]{2}\:[0-9a-fA-F]{2}/
    return pattern.test(text)
  }
  
  //判断email是否合法
  function checkEmail(text) {
    var pattern = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
      res = pattern.test(text)
    return res
  }
  
  //判断域名接口
  function checkUrl(text){
    let url = null;
    if(text.indexOf('cn')>0){
      url = text.slice(0,text.indexOf('cn')+2)
    }else{
      url = text.slice(0,text.indexOf('com')+3)
    }
    return url.replace(/(http:\/\/|https:\/\/).+?\.(.+)/g,"$1api.$2")
    // return 'http://api.k-radar.cn'
  }
  
  //判断域名接口
  function exposurekUrl(text){
    let url = null;
    if(text.indexOf('cn')>0){
      url = text.slice(0,text.indexOf('cn')+2)
    }else{
      url = text.slice(0,text.indexOf('com')+3)
    }
    return url.replace(/(http:\/\/|https:\/\/).+?\.(.+)/g,"$1u.$2")
    // return 'https://api.c-radar.cn'
  }
  
  //判断手机号码是否合法
  function checkPhoneNum(text) {
    var pattern = /[^0-9]/;
    if (pattern.test(text))
      return "手机号码只能是数字哟~"
  
    if (!text || text.split("").length != 11)
      return "手机号码必须是11位的数字哟~"
  
    return 0;
  }
  
  // 判断固定电话
  function isTelAvailable(number) {
    var pattern = /^0\d{2,3}-\d{7,8}$/;
    if (pattern.test(number))
      return true;
    return false;
  }
  
  // 判断手机号码
  function isPhoneAvailable(number) {
    var pattern = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (pattern.test(number))
      return true;
    return false;
  }
  
  // url
  function isUrl(url) {
    // var pattern = /^(https?:\/\/)?([a-zA-Z0-9\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/
    // var pattern = /^(https?:\/\/)?(([a-zA-Z0-9_-])+(\.)([a-zA-Z0-9_-])+){1}((\.)([a-zA-Z0-9_-])+)*(:\d+)?(\/#?((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i
    var pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&amp;=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&amp;=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&amp;;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
    if (pattern.test(url))
      return true;
    return false;
  }
  // 腾讯
  function isTXUrl(url) {
    // if (/^(https?:\/\/)?(mp.weixin.qq.com|(h5|topic|tsa).eqxiu.com|yiye.ai)((\/|\?)[\w+-=&\/\?]*)*$/.test(url))
    // if (/^(https?:\/\/)?(mp\.weixin\.qq\.com|[0-9a-zA-Z]+\.tsa\.yiye\.ai)((\/|\?)[\w+-=&\/\?]*)*$/.test(url))
    if (/^(https?:\/\/)?(mp\.weixin\.qq\.com(\/|(\?)((\?)?=?&?[a-zA-Z0-9_-](\?)?)*))|([0-9a-zA-Z]+\.tsa\.yiye\.ai)((\/|\?)[\w+-=&\/\?]*)*$/.test(url))
      return true;
    return false;
  }
  
  // 朋友圈
  function isFriendUrl(url) {
    if (/^(https?:\/\/)?mp\.weixin\.qq\.com((\/|\?)[\w+-=&\/\?]*)*$/.test(url))
      return true;
    return false;
  }
  
  // 头条
  function isOEUrl(url, extra = []) {
    let valid = false, type = ['chengzi', 'weixin'].concat(extra)
    type.forEach(v => {
      if(v === 'toutiao') {
        if(/^(https?:\/\/)?www.toutiao.com((\/|\?)[\w+-=#&\/\?]*)*$/.test(url)) {
          if(!valid) valid = true
        }
      }
      if(v === 'douyin') {
        if(/^(https?:\/\/)?www.douyin.com((\/|\?)[\w+-=#&\/\?]*)*$/.test(url)) {
          if(!valid) valid = true
        }
      }
      if(v === 'chengzi') {
        if(/^(https?:\/\/)?www.chengzijianzhan.com((\/|\?)[\w+-=#&\/\?]*)*$/.test(url)) {
          if(!valid) valid = true
        }
      }
      if(v === 'weixin') {
        if(/^(https?:\/\/)?mp.weixin.qq.com((\/|\?)[\w+-=#&\/\?]*)*$/.test(url)) {
          if(!valid) valid = true
        }
      }
    })
    return valid
  }
  //获取对象的值
  //list 为数组，key 为需要去除的key ， type 为最终获取的值的类型
  function getObjValue(list, key, type) {
  
    var result = [];
    for (var i = 0; i < list.length; i++) {
      //如果key 是数组，则返回多个对象【数组形式】
      if (typeof key != "string") {
        var obj = {};
        //取出每个key 列表的值，并且付给 obj[key]
        for (var j = 0; j < key.length; j++) {
          obj[key[j]] = list[i][key[j]];
        }
        result.push(obj);
      } else {
        var v = list[i][key]
        if (type == "int")
          v = parseInt(v);
  
        result.push(v)
      }
    }
    return result;
  }
  
  function checkInput(ori) {
    var msg = "";
    for (var key in ori) {
      if (key == "email") {
        msg = checkEmail(ori[key]) ? 0 : "Email 不合法，请检查输入。";
  
      } else if (key == "mac") {
        msg = ori[key] && checkMac(ori[key]) ? 0 : "Mac 不合法(参考 aa:aa:aa:aa:aa:aa)";
  
      } else if (key == "phoneNum") {
        if (ori[key] === "")
          msg = 0;
        else
          msg = checkPhoneNum(ori[key])
  
      } else if (key == "lng") {
        if (parseFloat(ori[key]) >= 0 && parseFloat(ori[key]) <= 180)
          msg = 0;
        else if (ori[key] === "")
          msg = "经度不能为空。";
        else
          msg = "经度只能是0-180之间哟 ~";
  
      } else if (key == "lat") {
        if (parseFloat(ori[key]) >= 0 && parseFloat(ori[key]) <= 90)
          msg = 0;
        else if (ori[key] === "")
          msg = "纬度不能为空。";
        else
          msg = "纬度只能是0-90之间哟 ~";
  
      } else if (key == "address") {
        msg = ori[key] ? 0 : "地址不能为空。";
  
      }
  
      //判断，如果多个输入有一个不合规，则终止判断
      if (msg !== 0) return msg
    }
    return msg
  }
  
  /*
  *
  *  cookie  相关
  *
  * */
  //写cookies
  function setCookie(name, value) {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
  }
  
  //读取cookies
  function getCookie(name) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  
    if (arr = document.cookie.match(reg))
  
      return unescape(arr[2]);
    else
      return null;
  }
  
  //删除cookies
  function delCookie(name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null)
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
  }
  function formatSecond(v) {
    if (v < 60) {
      return v.toFixed(2) + '秒'
    } else if (v < 3600) {
      return Math.floor(v / 60) + '分钟' + Math.ceil(v % 60) + '秒'
    } else {
      return Math.floor(v / 3600) + '小时' + Math.floor(v % 3600 / 60) + '分钟' + Math.ceil(v % 60) + '秒'
    }
  }
  export {
    openServices,
    localSave,
    getDateTime,
    transCommDate,
    objFilter,
    checkMac,
    isUrl,
    isTXUrl,
    isFriendUrl,
    isOEUrl,
    transChineseDate,
    setCookie,
    delCookie,
    getCookie,
    checkEmail,
    checkUrl,
    exposurekUrl,
    transCommDates,
    checkPhoneNum,
    getDateTimeYesterday,
    getDateTimeSeven,
    getDateTimeMonth,
    formatterDate,
    isTelAvailable,
    isPhoneAvailable,
    getDateTimeTomorrow,
    getDateTimeToday,
    getDateRange,
    judgeImageSize,
    getDateTimeFifteen,
    intimeDayChinese,
    wxValidityDate,
    intimeMinChinese,
    getDateTimePass,
    formatSecond,
    judgeCouponExpire,
    dateToMs
  }
  