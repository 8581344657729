import * as sa from './util'
import ajax from 'axios'
import {Message} from 'element-ui'

ajax.defaults.withCredentials = true;


///处理请求头
//同时接收一个提示回调
function disposeJsonHeader(json,tip){
  var result = false,
      show = tip && tip.warning || function(){};
  if(json && (json.code === 0 || json.status === 0  )){
    if(json.data){
      if(Object.keys(json.data).length || json.msg === 'ok') {
        result = json.data;
      }else if(typeof json.data == 'number'){
        result = json;
      }else{
        show('没有获取到相关数据', {
          duration: 5000
        });
      }
      //没有data 则直接返回json
    }else{
      result = json;
    }

  }else if(json.code == 500){
    show(json.msg, {
      duration: 5000
    });

  }else if(json.code == 401 || json.code == 403){
    console.log('401')
    show('登录信息失效，请重新登录', {
      duration: 5000
    });
    saLogout();

    //显示错误
  } else if (json.status === 10156) {
    Message.warning('由于分析样本数量偏少,敬请谅解,有问题请邮件联系service@c-radar.cn')
  }else{
    show(json.msg, {
      duration: 5000
    });
  }

  return result;
}

//普通的get请求
function normalGet(url,params,success,error,tip,always){
  let suc = success || function(){},
      err = error || function(){},
      al = always || function(){};

  ajax.get(url,{params:params}).then((res) =>{
    al(res.data.data);
    if(res.data){
      try{
        let data = disposeJsonHeader(res.data,tip);
        if(data) suc(data);
      }catch (e){
        console.warn(e);
      }
    }

  }).catch((e) =>{
    al();
    err();
    console.log('err ')
  })
}

function disposeJsonHeaders(json,tip){
  var result = false,
      show = tip && tip.warning || function(){};

  if(json){
    if(json.data){
      if(!Object.keys(json.data).length) {
        show('没有获取到相关数据', {
          duration: 5000
        });
      }else{
        result = json.data;

      }

      //没有data 则直接返回json
    }else{
      result = json;
    }

  }else if(json.code == 500){
    show(json.msg, {
      duration: 5000
    });

  }else if(json.code == 401 || json.code == 403){
    console.log('401')
    show('登录信息失效，请重新登录', {
      duration: 5000
    });
    // saLogout();

    //显示错误
  }else{
    show(json.msg, {
      duration: 5000
    });
  }

  return result;
}

//普通的get请求
function normalGets(url,params,success,error,tip,always){
  let suc = success || function(){},
      err = error || function(){},
      al = always || function(){};

  ajax.get(url,{params:params}).then((res) =>{
    al();

    if(res.data){
      try{
        let data = disposeJsonHeaders(res.data,tip);
        if(data) suc(data);
      }catch (e){
        console.warn(e);
      }
    }

  }).catch((e) =>{
    al();
    err();
    console.log('err ')
  })
}


//普通post请求
function normalPost(url,params,success,error,tip,always,incorrect){
  let suc = success || function(){},
      err = error || function(){},
      al = always || function(){};

  ajax.post(url,params).then((res) =>{
    al();
    if(res.data){
      try{
        let data = disposeJsonHeader(res.data,tip);
        if(data) suc(data);
        else if(incorrect) {
          incorrect()
        }
      }catch (e){
        console.warn(e);
      }
    }
  }).catch((e) =>{
    console.log('catch err')
    al();
    err();
    console.log('post err')
  })
}

function normalPosts(url,params,success,error,tip,always,type){
  let suc = success || function(){},
      err = error || function(){},
      al = always || function(){};

  ajax({
    method: 'post',
    url: url,
    data:params,
    headers:{'Content-type': type || 'application/x-www-form-urlencoded'}
  }).then((res) =>{
    al();
    if(res.data){
      try{
        let data = disposeJsonHeader(res.data,tip);
        if(data) suc(data);
      }catch (e){
        console.warn(e);
      }
    }
  }).catch((e) =>{
    console.log('catch err')
    al();
    err();
    console.log('post err')
  })
}

//设置token
function saSetToken(t) {
  let token = '';
  if (t) {
    token = t;
    sa.localSave('byToken', token);
  } else {
    token = sa.localSave('byToken');
  }

  ajax.defaults.headers.token = token;
  return token;
}

//获取当前用户信息
let saGetUserInfo = function () {
  return sa.localSave('byUserInfo') || false;
}
function isIE() {
  return ((navigator.appName == 'Microsoft Internet Explorer') || ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null)));
}

//退出登录
function saLogout() {
  sa.localSave('byUserInfo', '');
  sa.localSave('byToken', '');
  window.sessionStorage.clear()
  delete ajax.defaults.headers.token
  // console.log("logout");
  // location.href = '#/signIn';
  if(isIE()) {  // MSIE内核        
    window.location.reload()
  }
}
//链接本地服务器，更改token [测试用]
let devPrevRequest = function(callback){
  let token = sa.localSave('byToken');
  saSetToken('test');
  callback();
  saSetToken(token);
}
function transUrlStr(obj){
  let str = '?';
  for(var k in obj){
    str += str.length > 1 ? '&' : '';
    str += k + '=' + obj[k];
  }
  return str.length > 1 ? str : '';
}

export  {
  isIE,
  normalGet,
  normalGets,
  normalPost,
  saSetToken,
  saGetUserInfo,
  saLogout,
  devPrevRequest,
  transUrlStr,
  normalPosts,
}
