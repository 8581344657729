import fetch from './fetch'

class api {
  // 登录
  login(data) {
    return fetch({
      url: '/login',
      method: 'post',
      data: Object.assign({
        app: 'by'
      }, data)
    })
  }    
  //退出登录/
  logout() {
    return fetch({
      url: '/logout',
      method: 'get'
    })
  }
  //获取用户信息
  getUserInfo(params) {
    return fetch({
      url: '/upms/user/info',
      method: 'get',
      params: params
    })
  } 
  //修改密码
  setUsePasswordUpdate(data) {
    return fetch({
      url: '/upms/user/password',
      method: 'post',
      data: data
    })
  } 
  //添加门店
  shopAdd(data) {
    return fetch({
      url: '/shop/shop/add',
      method: 'post',
      data: data
    })
  }  
  //门店列表
  shopList(params) {
    return fetch({
      url: '/shop/shop/list',
      method: 'get',
      params: params
    })
  }  
  //获取门店详情
  getShopInfo(params) {
    return fetch({
      url: '/shop/shop/get',
      method: 'get',
      params: params
    })
  } 
  //修改门店
  updateShop(data) {
    return fetch({
      url: '/shop/shop/update',
      method: 'post',
      data: data
    })
  }  
  //删除门店
  deleteShop(data) {
    return fetch({
      url: '/shop/shop/delete',
      method: 'post',
      data: data
    })
  } 
  //添加分类
  addTheClassification(data) {
    return fetch({
      url: '/shop/mediaCatalog/add',
      method: 'post',
      data: data
    })
  } 
  //获取分类结构
  getListCatalog(params) {
    return fetch({
      url: '/shop/mediaCatalog/listCatalog',
      method: 'get',
      params: params
    })
  } 
  //上传图片素材
  uploadImageMaterial(data) {
    return fetch({
      url: '/shop/media/uploadImgUrl',
      method: 'post',
      data: data
    })
  } 
  // 上传素材
  uploadMediaUrl(data) {
    return fetch({
      url: '/shop/media/uploadMediaUrl',
      method: 'post',
      data: data
    })
  } 
  //上传视频素材
  uploadVideoMaterial(data) {
    return fetch({
      url: '/shop/media/uploadVideoUrl',
      method: 'post',
      data: data
    })
  } 
  //获取素材列表
  getStoriesList(params) {
    return fetch({
      url: '/shop/media/listMedia',
      method: 'get',
      params: params
    })
  } 
  //修改素材所属分类
  modifyCategory(data) {
    return fetch({
      url: '/shop/media/update',
      method: 'post',
      data: data
    })
  } 
  //获取素材分类详情
  getmediaCatalogInfo(params) {
    return fetch({
      url: '/shop/mediaCatalog/get',
      method: 'get',
      params: params
    })
  } 
  //修改素材分类名
  updateMediaCatalogName(data) {
    return fetch({
      url: '/shop/mediaCatalog/update',
      method: 'post',
      data: data
    })
  } 
  //素材删除 - 图片、视频
  deleteMediaCatalogName(data) {
    return fetch({
      url: '/shop/media/delete',
      method: 'post',
      data: data
    })
  } 
  //删除素材分类
  deleteMediaCatalog(data) {
    return fetch({
      url: '/shop/mediaCatalog/delete',
      method: 'post',
      data: data
    })
  } 




  //创建商品
  addGoodsPost(data) {
    return fetch({
      url: '/shop/goods/add',
      method: 'post',
      data: data
    })
  } 
  //修改商品
  updateGoodsPost(data) {
    return fetch({
      url: '/shop/goods/update',
      method: 'post',
      data: data
    })
  } 
  //获取商品信息
  getGoodsInfoPost(params) {
    return fetch({
      url: '/shop/goods/get',
      method: 'get',
      params: params
    })
  } 
  //门店获取商品列表
  getGoodsByShopList(params) {
    return fetch({
      url: '/shop/goods/list/byShop',
      method: 'get',
      params: params
    })
  } 
  //品牌方获取商品列表
  getGoodsByBrandList(params) {
    return fetch({
      url: '/shop/goods/list/byBrand',
      method: 'get',
      params: params
    })
  } 
  //删除商品
  deleteGoodsPost(data) {
    return fetch({
      url: '/shop/goods/delete',
      method: 'post',
      data: data
    })
  } 
  //创建商品类目
  addGoodsCatalog(data) {
    return fetch({
      url: '/shop/goodsCatalog/add',
      method: 'post',
      data: data
    })
  } 
  //获取商品类目结构
  getListCatalogType(params) {
    return fetch({
      url: '/shop/goodsCatalog/listCatalog',
      method: 'get',
      params: params
    })
  } 
  //删除商品类目结构
  deleteGoodsCatalog(data) {
    return fetch({
      url: '/shop/goodsCatalog/delete',
      method: 'post',
      data: data
    })
  }
  //获取商品类目详情
  getGoodsCatalogInfo(params) {
    return fetch({
      url: '/shop/goodsCatalog/get',
      method: 'get',
      params: params
    })
  }  
  //修改商品分类名
  updateGoodsCatalogInfo(data) {
    return fetch({
      url: '/shop/goodsCatalog/update',
      method: 'post',
      data: data
    })
  }  
  //修改商品状态
  updateGoodsCatalogStatus(data) {
    return fetch({
      url: '/shop/goods/update/status',
      method: 'post',
      data: data
    })
  } 



  //添加配件
  addAccessories(data) {
    return fetch({
      url: '/shop/accessories/add',
      method: 'post',
      data: data
    })
  } 
  //修改配件信息
  updateAccessories(data) {
    return fetch({
      url: '/shop/accessories/update',
      method: 'post',
      data: data
    })
  } 
  //查看配件信息
  getAccessoriesInfo(params) {
    return fetch({
      url: '/shop/accessories/get',
      method: 'get',
      params: params
    })
  } 
  //删除配件
  deleteAccessories(data) {
    return fetch({
      url: '/shop/accessories/delete',
      method: 'post',
      data: data
    })
  } 
  //配件列表
  getAccessoriesList(params) {
    return fetch({
      url: '/shop/accessories/list',
      method: 'get',
      params: params
    })
  } 
  //添加配件分类
  addCatalogType(data) {
    return fetch({
      url: '/shop/accessories/catalog/add',
      method: 'post',
      data: data
    })
  } 
  //获取配件分类结构
  getCatalogList(params) {
    return fetch({
      url: '/shop/accessories/catalog/listCatalog',
      method: 'get',
      params: params
    })
  } 
  //删除分类
  deleteCatalogType(data) {
    return fetch({
      url: '/shop/accessories/catalog/delete',
      method: 'post',
      data: data
    })
  } 
  //获取分类详情
  getCatalogInfoType(params) {
    return fetch({
      url: '/shop/accessories/catalog/get',
      method: 'get',
      params: params
    })
  } 
  //修改配件分类
  updateCatalogType(data) {
    return fetch({
      url: '/shop/accessories/catalog/update',
      method: 'post',
      data: data
    })
  } 


  //首页装修店铺
  addHomePage(data) {
    return fetch({
      url: '/shop/homePage/add',
      method: 'post',
      data: data
    })
  } 
  //首页装修店铺-添加与修改
  saveOrUpdateHome(data) {
    return fetch({
      url: '/shop/homePage/saveOrUpdate',
      method: 'post',
      data: data
    })
  } 
  //修改店铺首页信息
  updateHomePage(data) {
    return fetch({
      url: '/shop/homePage/update',
      method: 'post',
      data: data
    })
  }
  //首页信息详情
  getHomePage(params) {
    return fetch({
      url: '/shop/homePage/get',
      method: 'get',
      params: params
    })
  }

  //添加店铺案例
  addHomeCase(data) {
    return fetch({
      url: '/shop/shopCase/add',
      method: 'post',
      data: data
    })
  }
  //案例列表
  getShopCaseList(params) {
    return fetch({
      url: '/shop/shopCase/list',
      method: 'get',
      params: params
    })
  }
  //删除店铺案例
  deleteHomeCase(data) {
    return fetch({
      url: '/shop/shopCase/delete',
      method: 'post',
      data: data
    })
  }
 
     
}
export default api