import Vue from 'vue'
import VueRouter from 'vue-router'

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, resolve, reject) {
//     if ( resolve || reject ) return originalPush.call(this, location, resolve, reject)
//     return originalPush.call(this, location).catch(err => err)
// }

Vue.use(VueRouter)

const routes = [
  //admin
  {
    path: '/adminHome',
    name:"adminHome",
    component: resolve => require(['../pages/Admin/HomePage/admin'], resolve),
    children:[
      // {
      //   path: '/',
      //   name:'adminIndex',
      //   component: resolve => require(['../pages/Admin/HomePage/adminIndex'], resolve),   
      //   meta:{ requireAuth:true,roleId:0 }
      // },        
      {
        path: '/storeAccountManager',
        name:'storeAccountManager',
        component: resolve => require(['../pages/Admin/AccountManager/storeAccountManager'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      },  
      // 商品管理
      {
        path: '/productList',
        name:'productList',
        component: resolve => require(['../pages/Admin/GoodsManager/productList'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      },  
      {
        path: '/productClassify',
        name:'productClassify',
        component: resolve => require(['../pages/Admin/GoodsManager/productClassify'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      {
        path: '/goodsReleased',
        name:'goodsReleased',
        component: resolve => require(['../pages/Admin/GoodsManager/goodsReleased'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      //配件管理
      {
        path: '/mountingsList',
        name:'mountingsList',
        component: resolve => require(['../pages/Admin/MountingsManager/mountingsList'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      {
        path: '/mountingsClassify',
        name:'mountingsClassify',
        component: resolve => require(['../pages/Admin/MountingsManager/mountingsClassify'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      {
        path: '/newMountings',
        name:'newMountings',
        component: resolve => require(['../pages/Admin/MountingsManager/newMountings'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      // 店铺装修
      // {
      //   path: '/caseDecoration',
      //   name:'caseDecoration',
      //   component: resolve => require(['../pages/Admin/ShopDecoration/caseDecoration'], resolve),   
      //   meta:{ requireAuth:true,roleId:0 }
      // }, 
      // {
      //   path: '/goodsDecoration',
      //   name:'goodsDecoration',
      //   component: resolve => require(['../pages/Admin/ShopDecoration/goodsDecoration'], resolve),   
      //   meta:{ requireAuth:true,roleId:0 }
      // },
      // {
      //   path: '/homeDecoration',
      //   name:'homeDecoration',
      //   component: resolve => require(['../pages/Admin/ShopDecoration/homeDecoration'], resolve),   
      //   meta:{ requireAuth:true,roleId:0 }
      // },
      //素材管理
      {
        path: '/materialCenter',
        name:'materialCenter',
        component: resolve => require(['../pages/Admin/MaterialManager/materialCenter'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      },
      //系统设置
      {
        path: '/editPassword',
        name:'editPassword',
        component: resolve => require(['../pages/Admin/PersonCenter/editPassword'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      },         
    ]
  },

  //store
  {
    path: '/store',
    name:"store",
    component: resolve => require(['../pages/Stores/HomePage/store'], resolve),
    children:[
      {
        path: '/',
        name:'sHomeDecoration',
        component: resolve => require(['../pages/Stores/ShopDecoration/sHomeDecoration'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      },
      {
        path: '/sProductList',
        name:'sProductList',
        component: resolve => require(['../pages/Stores/GoodsManager/sProductList'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      {
        path: '/sProductClassify',
        name:'sProductClassify',
        component: resolve => require(['../pages/Stores/GoodsManager/sProductClassify'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      {
        path: '/sGoodsReleased',
        name:'sGoodsReleased',
        component: resolve => require(['../pages/Stores/GoodsManager/sGoodsReleased'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      // //配件管理
      {
        path: '/sMountingsClassify',
        component: resolve => require(['../pages/Stores/MountingsManager/sMountingsClassify'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      {
        path: '/sMountingsList',
        component: resolve => require(['../pages/Stores/MountingsManager/sMountingsList'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      {
        path: '/sNewMountings',
        component: resolve => require(['../pages/Stores/MountingsManager/sNewMountings'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      // //店铺装修
      {
        path: '/sHomeDecoration',
        component: resolve => require(['../pages/Stores/ShopDecoration/sHomeDecoration'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      {
        path: '/sGoodsDecoration',
        component: resolve => require(['../pages/Stores/ShopDecoration/sGoodsDecoration'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      {
        path: '/sCaseDecoration',
        component: resolve => require(['../pages/Stores/ShopDecoration/sCaseDecoration'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      }, 
      // //素材管理
      {
        path: '/sMaterialCenter',
        component: resolve => require(['../pages/Stores/MaterialManager/sMaterialCenter'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      },
      //系统设置
      {
        path: '/sEditPassword',
        component: resolve => require(['../pages/Stores/PersonCenter/sEditPassword'], resolve),   
        meta:{ requireAuth:true,roleId:0 }
      },
    ]    
  },
  {
    path: '/',
    redirect: '/signIn',
  },
  {
    path: '/signIn',
    name:"signIn",
    component: resolve => require(['../pages/SignIn/signIn'], resolve),
  },  
  {
    path: '/admin',
    name:"signInAdmin",
    component: resolve => require(['../pages/SignIn/signInAdmin'], resolve),
  },     
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
// router.beforeEach((to, from, next) => {

//   if(to.path === '/signIn') return next();
//   const tokenstr = window.localStorage.getItem('byToken')
//   console.log("token",tokenstr);
//   if(!tokenstr) return next('/signIn')
//   next()
  
// })

export default router
