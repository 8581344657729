import axios from 'axios'
import { Message } from 'element-ui'
import { saLogout } from '@/methods'
import { localSave } from '@/methods/util'


// const api = {
//   'qwt.bjeizoq.cn': 'http://api.shangzhi1234.cn',
//   'qwt.shangzhi1234.cn': 'http://api.shangzhi1234.cn'
// }

// localSave('apiName', api)
// let location = window.location.hostname

const service = axios.create({
  baseURL: 'https://elk.condco.cn/',// 接口的域名地址
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

//设置axios请求头加入token
service.interceptors.request.use(config => { 
  if (config.push === '/') { 
   } else { 
    if (localSave('byToken')) { 
      config.headers.token = localSave('byToken');  
    } 
   } 
    return config; 
  }, 
  error => { 
   return Promise.reject(error);
});
 



export default function fetch(options, setting={}) {
  return new Promise((resolve, reject) => {
    service(options)
      .then(response => {
        resolve(response.data)
        let code = response.data.code === 0 || response.data.status === 0 || response.data.msg === 'Server internal error!'
        if (!code && response.data.msg.indexOf('retail-help@baidu.com') === -1) {
          let wxErrorCode = response.data.data && response.data.data.wxErrorCode ? response.data.data.wxErrorCode : 0
          if (!setting.noTip) {
            if (!wxErrorCode) {
              Message.closeAll()
              Message.warning(response.data.msg)
            }
          }
        }
        // if (response.data.code === 401) {
        //   saLogout()
        // }
      })
      .catch(error => {
        console.log('请求异常信息:' + error)
        reject(error)
      })
  })
}
