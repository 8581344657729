<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { saGetUserInfo, saLogout, saSetToken } from "./methods";

export default {
  name: "App",
  beforeCreate() {
    let userInfo = saGetUserInfo();
    saSetToken();
    if (this.$route.path !== "/signIn" && this.$route.path !== "/admin" && this.$route.path !== "/") {
      if (!userInfo) {
        this.$message.error("无用户登录信息,请输入账号密码");
        this.$router.push("/signIn");
      } else {
        // this.$router.push("/home");
      }
    } else {
      if (userInfo) {
        // this.$router.push("/home");
      } else {
        this.$router.push("/signIn");
      }
    }
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  font-family: "Microsoft Yahei Regular", "SF Pro SC", "SF Pro Display",
    "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
</style>
