var adminMenu = [
    {
        icon:"iconfont icon-shouye2",
        index:"adminHome",
        sbuMenu:false,
        subs:null,
        title:"首页",
        type:0,    
    },
    {
        icon:"iconfont icon-xiaoshou",
        index:"accountManage",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"storeAccountManager",
                sbuMenu:false,
                subs:null,
                title:"门店列表",
                type:0,    
            },            
        ],
        title:"门店管理",
        type:0,    
    },
    {
        icon:"iconfont icon-xiaoshou",
        index:"goodsManager",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"productList",
                sbuMenu:false,
                subs:null,
                title:"商品列表",
                type:0,    
            },    
            {
                icon:"",
                index:"productClassify",
                sbuMenu:false,
                subs:null,
                title:"商品分类",
                type:0,    
            },         
        ],
        title:"商品管理",
        type:0,    
    },
    {
        icon:"iconfont icon-xiaoshou",
        index:"mountingsManager",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"mountingsList",
                sbuMenu:false,
                subs:null,
                title:"配件列表",
                type:0,    
            },    
            {
                icon:"",
                index:"mountingsClassify",
                sbuMenu:false,
                subs:null,
                title:"配件分类",
                type:0,    
            },         
        ],
        title:"配件管理",
        type:0,    
    },
    // {
    //     icon:"iconfont icon-xiaoshou",
    //     index:"shopDecoration",
    //     sbuMenu:true,
    //     subs:[
    //         {
    //             icon:"",
    //             index:"homeDecoration",
    //             sbuMenu:false,
    //             subs:null,
    //             title:"首页装修",
    //             type:0,    
    //         },    
    //         {
    //             icon:"",
    //             index:"goodsDecoration",
    //             sbuMenu:false,
    //             subs:null,
    //             title:"商品页装修",
    //             type:0,    
    //         }, 
    //         {
    //             icon:"",
    //             index:"caseDecoration",
    //             sbuMenu:false,
    //             subs:null,
    //             title:"案例页装修",
    //             type:0,    
    //         },         
    //     ],
    //     title:"店铺装修",
    //     type:0,    
    // },
    {
        icon:"iconfont icon-xiaoshou",
        index:"materialManager",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"materialCenter",
                sbuMenu:false,
                subs:null,
                title:"素材中心",
                type:0,    
            },    
        ],
        title:"素材管理",
        type:0,    
    },
    {
        icon:"iconfont icon-yonghu",
        index:"personCenter",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"editPassword",
                sbuMenu:false,
                subs:null,
                title:"修改密码",
                type:0,    
            },              
        ],
        title:"系统设置",
        type:0,    
    },        
]

var storeMenu = [
    {
        icon:"iconfont icon-xiaoshou",
        index:"shopDecoration",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"sHomeDecoration",
                sbuMenu:false,
                subs:null,
                title:"首页装修",
                type:0,    
            },
            {
                icon:"",
                index:"sGoodsDecoration",
                sbuMenu:false,
                subs:null,
                title:"商品页装修",
                type:0,    
            },
            {
                icon:"",
                index:"sCaseDecoration",
                sbuMenu:false,
                subs:null,
                title:"案例页装修",
                type:0,    
            }
        ],
        title:"店铺装修",
        type:0,    
    },  
    // {
    //     icon:"iconfont icon-shouye2",
    //     index:"store",
    //     sbuMenu:false,
    //     subs:null,
    //     title:"首页",
    //     type:0,    
    // },
    {
        icon:"iconfont icon-xiaoshou",
        index:"goodsManager",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"sProductList",
                sbuMenu:false,
                subs:null,
                title:"商品列表",
                type:0,    
            },  
            {
                icon:"",
                index:"sProductClassify",
                sbuMenu:false,
                subs:null,
                title:"商品分类",
                type:0,    
            },          
        ],
        title:"商品管理",
        type:0,    
    },
    {
        icon:"iconfont icon-xiaoshou",
        index:"mountings",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"sMountingsList",
                sbuMenu:false,
                subs:null,
                title:"配件列表",
                type:0,    
            },  
            {
                icon:"",
                index:"sMountingsClassify",
                sbuMenu:false,
                subs:null,
                title:"配件分类",
                type:0,    
            },          
        ],
        title:"配件管理",
        type:0,    
    },
    
    {
        icon:"iconfont icon-xiaoshou",
        index:"material",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"sMaterialCenter",
                sbuMenu:false,
                subs:null,
                title:"素材中心",
                type:0,    
            },
        ],
        title:"素材管理",
        type:0,    
    },   
    {
        icon:"iconfont icon-yonghu",
        index:"personCenter",
        sbuMenu:true,
        subs:[
            {
                icon:"",
                index:"sEditPassword",
                sbuMenu:false,
                subs:null,
                title:"修改密码",
                type:0,    
            },              
        ],
        title:"系统设置",
        type:0,    
    },        
]

var menuJson = {
    adminMenu,
    storeMenu
}

export default menuJson