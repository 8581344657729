import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'; 
import 'element-ui/lib/theme-chalk/index.css';
import menu from './internal/menuJson'

import './assets/elementCss/dialogCss.css'
import api from "@/axios/api"; // 导入接口

const API = new api()

Vue.prototype.$API = API
Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.$menu = menu

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
